import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";

// Date Picker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

// Assets
import redeemedImg from "../../assets/redeem.svg";

// Translation
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { WALLET_API } from "../../lib/axios";

export default function RedemptionDialog({ ...props }) {
  const { t } = useTranslation("common");
  const { isOpen, isClose, profile } = props;
  const auth = localStorage.getItem("auth");
  const navigate = useNavigate();

  const [bookings, setBookings] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([8, 10, 12]);
  useEffect(() => {
    if (auth == null) {
      navigate("/login");
    } else {
      WALLET_API.get("/account/redeem", {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      }).then((res) => setBookings(res.data));
    }
  }, [auth, navigate]);

  const handleBookingDateChange = (newdob) => {
    const bookingDate = dayjs(newdob);
    const slots = [8, 10, 12].filter((x) => {
      console.log(bookings);

      const booked = bookings
        .filter((v) => dayjs(bookingDate).isSame(v.appointmentDate))
        .map((v) => v.timeSlot);
      return !booked.includes(x);
    });
    setAvailableSlots(slots);
    return setFormData({
      ...formData,
      appointmentDate: newdob,
    });
  };

  const defaultForm = {
    walletaddress: !profile.walletaddress ? "-" : profile.walletaddress,
    nik: !profile.nik ? "-" : profile.nik,
    fullname: !profile.fullname ? "-" : profile.fullname,
    email: !profile.email ? "-" : profile.email,
    appointmentDate: dayjs(null),
  };

  const [formData, setFormData] = useState(defaultForm);
  const [redemptionAmount, setRedemptionAmount] = useState("");

  const handleChangeRedemptionAmount = (e) => {
    setRedemptionAmount(e.target.value);
  };

  const [timeSlot, setTimeSlot] = useState("");

  const handleChangeTimeSlot = (e) => {
    setTimeSlot(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const toSend = {
      ...formData,
      amount: redemptionAmount,
      timeSlot,
    };
    WALLET_API.post("/account/redeem/request", toSend, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    })
      .then(() => {
        navigate("/?msg=redeem");
        navigate(0);
      })
      .catch(() => {
        navigate("/?err=redeem");
        navigate(0);
      });
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={isClose}
      maxWidth={"sm"}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Typography variant="h4" fontWeight="600">
          {t("redeemGIDR.modal.title")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12} sx={{ textAlign: "center", mb: 1 }}>
            <img src={redeemedImg} width="220px" alt="GIDR - Redeemed" />
          </Grid>
          <Grid item xs={12} md={12} sx={{ mb: 1 }}>
            <Typography variant="body1" fontWeight="600">
              {t("redeemGIDR.modal.accountInformation.title")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="body2" fontWeight="600" sx={{ mb: "8px" }}>
                  {t("redeemGIDR.modal.accountInformation.walletAddress")}
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {defaultForm.walletaddress}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.nik")}
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {defaultForm.nik}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.fullname")}
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {defaultForm.fullname}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.emailAddress")}
                </Typography>
                <Typography variant="body2" color="#7e7e7e">
                  {defaultForm.email}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.kycStatus")}
                </Typography>
                <Chip
                  label={
                    profile.statusemail !== "UNVERIFIED"
                      ? t("kycStatus.verified")
                      : t("kycStatus.notVerified")
                  }
                  color={
                    profile.statusemail !== "UNVERIFIED" ? "success" : "error"
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Collection Date
                </Typography>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"en-gb"}
                >
                  <DatePicker
                    onChange={handleBookingDateChange}
                    disablePast
                    name="collectionDate"
                    id="collectionDate"
                    value={formData.appointmentDate || ""}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "50px",
                        fontSize: "14px",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.timeSlot")}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    disabled={!formData.appointmentDate.isValid()}
                    value={timeSlot}
                    fullWidth
                    displayEmpty
                    onChange={handleChangeTimeSlot}
                    style={{ height: "50px", fontSize: "14px" }}
                  >
                    <MenuItem value="">
                      <em>
                        {!formData.appointmentDate.isValid()
                          ? t(
                              "redeemGIDR.modal.accountInformation.timeSlotPlaceholderEmptyDate"
                            )
                          : availableSlots.length === 0
                          ? "No slots available"
                          : t(
                              "redeemGIDR.modal.accountInformation.timeSlotPlaceholder"
                            )}
                      </em>
                    </MenuItem>
                    {availableSlots.map((x, i) => {
                      return (
                        <MenuItem value={x} key={i}>
                          {x >= 10 ? x.toString() : "0" + x}:00 - {x + 2}:00 WIB
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("redeemGIDR.modal.accountInformation.redemptionAmount")}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={redemptionAmount}
                    fullWidth
                    label={null}
                    onChange={handleChangeRedemptionAmount}
                    style={{ height: "50px", fontSize: "14px" }}
                  >
                    <MenuItem value="">
                      <em>
                        {t(
                          "redeemGIDR.modal.accountInformation.redemptionAmountPlaceholder"
                        )}
                      </em>
                    </MenuItem>
                    {[1, 5, 10].map((x, i) => (
                      <MenuItem value={x} key={i}>{x} GIDR</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  disabled={
                    profile.statusemail === "UNVERIFIED" ||
                    redemptionAmount === ""
                  }
                  sx={{ fontWeight: "600", boxShadow: "unset" }}
                  fullWidth
                  onClick={handleFormSubmit}
                >
                  {t("redeemGIDR.modal.accountInformation.button")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={isClose}>{t("redeemGIDR.modal.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
