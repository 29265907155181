import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useSearchParams } from "react-router-dom";

// Assets
import gidrLogo from "../../assets/gidr-logo.svg";

// API
import { WALLET_API } from "../../lib/axios";

export default function SuccessVerify() {
  const [message, setMsg] = useState("Loading...");
  const [success, setScs] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get("id");
    const token = searchParams.get("token");
    WALLET_API.post("/register/verify", {
      id,
      token,
    })
      .then((res) => res.data)
      .then(() => {
        setMsg("Successful Email Verification!");
        setScs(true);
      })
      .catch(() => {
        setMsg("Something went wrong, please try again!");
        setScs(false);
      });
  });

  return (
    <Container maxWidth="sm">
      <Paper sx={{ boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px" }}>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 4,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sx={{ mb: 1 }}>
              <img src={gidrLogo} alt="GIDR Logo" />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mb: 1 }}>
              <Typography variant="h3" align="left">
                {message}
              </Typography>
            </Grid>
            {success ? (
              <Grid item xs={12} md={12}>
                <Typography variant="body2" align="left">
                  Proceed to login to your account! 🙌🏻
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <Button
            fullWidth
            component={Link}
            to="/login"
            variant="contained"
            sx={{ mt: 3, color: "#ffffff", boxShadow: "none" }}
          >
            Back to Login
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
