import React, { useState, Fragment } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

// Components
import RedemptionInfoDialog from "../dialog/RedemptionInfo";

export default function ResponsiveAlert({ ...props }) {
  const { description, data } = props;
  const [redemptionInfoDialog, setRedemptionInfoDialog] = useState(false);

  const handleOpenRedemptionInfoDialog = () => {
    setRedemptionInfoDialog(true);
  };

  const handleCloseRedemptionInfoDialog = () => {
    setRedemptionInfoDialog(false);
  };

  return (
    <Fragment>
      <RedemptionInfoDialog
        data={data}
        isOpen={redemptionInfoDialog}
        isClose={handleCloseRedemptionInfoDialog}
      />
      <Alert
        // variant="filled"
        severity="info"
        action={
          <Button
            color="inherit"
            size="small"
            sx={{ fontWeight: 800 }}
            onClick={handleOpenRedemptionInfoDialog}
          >
            Read More
          </Button>
        }
      >
        {description}
      </Alert>
    </Fragment>
  );
}
