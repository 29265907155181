import React, { useState } from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Link, useNavigate } from "react-router-dom";

// Assets
import gidrLogo from "../../assets/gidr-logo.svg";

// Components
import Snackbar from "../../components/Snackbar";

// Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Interactions
import { WALLET_API } from "../../lib/axios";

// Phone Input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Translation
import { useTranslation } from "react-i18next";

export default function SignUp() {
  const { t } = useTranslation("common");

  const navigate = useNavigate();
  const defaultForm = {
    password: "",
    confpass: "",
    email: "",
    fullname: "",
    phonenumber: "",
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [message, setMessage] = useState("Something went wrong");
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [emailError, setEErr] = useState(false);
  const [emailHelp, setEHelp] = useState("");
  const [passError, setPErr] = useState(false);
  const [confError, setCErr] = useState(false);
  const [formData, setFormData] = useState(defaultForm);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formData.password !== formData.confpass) {
      setDataStatus(false);
      setMessage(t("alert.passwordMismatch"));
      setOpenAlert(true);
      setFormData(defaultForm);
      return;
    }

    const requirements = formData.password.match(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/
    );
    if (!requirements) {
      setDataStatus(false);
      setMessage(t("alert.passwordRules"));
      setOpenAlert(true);
      setFormData(defaultForm);
      return;
    }

    // Perform actions like sending data to server here (e.g., API call)
    WALLET_API.post("/register", formData)
      .then((res) => res.data.data)
      .then(() => {
        navigate("/login?msg=registered");
      })
      .catch(() => {
        setDataStatus(false);
        setMessage(t("alert.general"));
        setOpenAlert(true);
        setFormData(defaultForm);
      });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });

    if (event.target.name === "email") {
      const emailOnly = new RegExp("^[\\w-.]+@([\\w-]+.)+[\\w-]{2,4}$");
      const email = event.target.value;
      if (emailOnly.test(email)) {
        setEErr(false);
        setEHelp("");
      } else {
        setEErr(true);
        setEHelp("Invalid Email");
      }
    }

    if (event.target.name === "password") {
      const passOnly = new RegExp(
        "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,20}$"
      );
      const pass = event.target.value;
      if (passOnly.test(pass)) {
        setPErr(false);
      } else {
        setPErr(true);
      }
    }

    if (event.target.name === "confpass") {
      const conf = event.target.value;
      if (conf === formData.password) {
        setCErr(false);
      } else {
        setCErr(true);
      }
    }
  };

  return (
    <Container maxWidth="sm" sx={{ pb: "120px" }}>
      <Snackbar
        isOpen={openAlert}
        isSuccess={dataStatus}
        isClose={handleCloseAlert}
        message={message}
      />
      <Paper sx={{ boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px" }}>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 4,
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <img src={gidrLogo} width="155px" alt="GIDR Logo" />
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography variant="h3" align="left">
                  {t("signUp.title")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                sx={{ alignContent: "center", textAlign: "right" }}
              >
                <Link
                  to="/"
                  style={{
                    textDecoration: "unset",
                    fontSize: "14px",
                    color: "#E3AD1A",
                  }}
                >
                  {t("signUp.login")}
                </Link>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("signUp.fullnameLabel")}*
                </Typography>
                <TextField
                  required
                  fullWidth
                  placeholder={t("signUp.fullnamePlaceholder")}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  autoFocus
                  name="fullname"
                  id="fullname"
                  onChange={handleChange}
                  value={formData.fullname}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("signUp.emailAddressLabel")}*
                </Typography>
                <TextField
                  required
                  fullWidth
                  type="email"
                  placeholder={t("signUp.emailAddressPlaceholder")}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  name="email"
                  id="email"
                  onChange={handleChange}
                  error={emailError}
                  helperText={emailHelp}
                  value={formData.email}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("signUp.phoneNumberLabel")}*
                </Typography>
                <PhoneInput
                  country={"id"}
                  value={formData.phonenumber}
                  inputStyle={{
                    height: "48px",
                    width: "100%",
                    paddingLeft: "45px",
                  }}
                  inputProps={{
                    name: "phonenumber",
                    id: "phonenumber",
                    onChange: handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("signUp.passwordLabel")}*
                </Typography>
                <OutlinedInput
                  required
                  fullWidth
                  type={showPassword ? "password" : "text"}
                  placeholder={t("signUp.passwordPlaceholder")}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  name="password"
                  id="password"
                  onChange={handleChange}
                  value={formData.password}
                  error={passError}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  {t("signUp.confirmPasswordLabel")}*
                </Typography>
                <OutlinedInput
                  required
                  fullWidth
                  type={showConfirmPassword ? "password" : "text"}
                  placeholder={t("signUp.confirmPasswordPlaceholder")}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  name="confpass"
                  id="confpass"
                  onChange={handleChange}
                  value={formData.confpass}
                  error={confError}
                />
              </Grid>
              {passError ? (
                <Grid item xs={12} md={12}>
                  <Typography variant="caption" color="#FF0000">
                    *{t("signUp.passwordError")}
                  </Typography>
                </Grid>
              ) : null}
              {confError ? (
                <Grid item xs={12} md={12}>
                  <Typography variant="caption" color="#FF0000">
                    *{t("signUp.passwordMissmatch")}
                  </Typography>
                </Grid>
              ) : null}
              {/* <Grid item xs={12} md={12}>
              <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                Referral Code
              </Typography>
              <TextField
                fullWidth
                placeholder="Input Your Referral Code"
                inputProps={{
                  style: { fontWeight: 400, fontSize: "14px", height: "15px" },
                }}
              />
            </Grid> */}
              <Grid
                item
                xs={12}
                md={12}
                sx={{ alignContent: "center", textAlign: "left", mb: 2 }}
              >
                <Typography variant="caption" color="#7e7e7e">
                  *{t("signUp.terms")}
                </Typography>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, color: "#ffffff", boxShadow: "none" }}
            >
              {t("signUp.register")}
            </Button>
          </form>
        </Box>
      </Paper>
    </Container>
  );
}
