import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { Link, NavLink } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Assets
import gidrLogo from "../assets/gidr-logo.svg";

// Components
import LogoutDialog from "./dialog/Logout";

// Icons
import LogoutIcon from "@mui/icons-material/Logout";

// Translation
import { useTranslation } from "react-i18next";

export default function ResponsiveAppBar() {
  const { t, i18n } = useTranslation("common");

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [logoutDialog, setLogoutDialog] = useState(false);

  const navigation = [
    { id: 1, title: t("navigation.dashboard"), link: "/" },
    { id: 2, title: t("navigation.transactionHistory"), link: "/history" },
    { id: 3, title: t("navigation.redemption"), link: "/redemption" },
    { id: 4, title: t("navigation.profile"), link: "/profile" },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseLogOutDialog = () => {
    setLogoutDialog(false);
  };

  return (
    <AppBar
      position="static"
      sx={{ background: "none", boxShadow: "none", pt: 1 }}
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ p: 1, paddingLeft: "unset !important" }}>
          <Link to="/">
            <div style={{ marginRight: "40px" }}>
              <img
                src={gidrLogo}
                alt="GIDR Logo"
                width="135px"
                style={{
                  display: { xs: "none", md: "flex" },
                }}
              />
            </div>
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="medium"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "#fff" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {navigation.map((data) => (
                <MenuItem key={data.id} onClick={handleCloseNavMenu}>
                  <NavLink
                    key={data.id}
                    to={data.link}
                    style={({ isActive }) => {
                      return {
                        fontWeight: isActive ? "600" : "400",
                        color: isActive ? "#E3AD1A" : "#141517",
                        fontSize: "14px",
                        textDecoration: "none",
                        fontFamily: `"Manrope", sans-serif`,
                      };
                    }}
                  >
                    {data.title}
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              "& > :not(style) ~ :not(style)": {
                ml: 2,
              },
            }}
          >
            {navigation.map((data) => (
              <NavLink
                key={data.id}
                to={data.link}
                style={({ isActive }) => {
                  return {
                    fontWeight: isActive ? "600" : "400",
                    color: isActive ? "#E3AD1A" : "#ffffff",
                    fontSize: "14px",
                    textDecoration: "none",
                    fontFamily: `"Manrope", sans-serif`,
                  };
                }}
              >
                {data.title}
              </NavLink>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, color: "#ffffff", mr: 2 }}>
            <FormControl variant="standard">
              <Select
                value={i18n.language}
                disableUnderline
                onChange={(e) => {
                  i18n.changeLanguage(e.target.value);
                }}
                displayEmpty
                sx={{
                  height: 40,
                  color: "#ffffff",
                  fontWeight: 600,
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                }}
              >
                <MenuItem value={"en"}>EN</MenuItem>
                <MenuItem value={"id"}>ID</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Log Out" onClick={() => setLogoutDialog(true)}>
              <IconButton sx={{ p: 0 }}>
                <LogoutIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </Box>
          <LogoutDialog isOpen={logoutDialog} isClose={handleCloseLogOutDialog} />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
