import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import QRCode from "react-qr-code";
import Chip from "@mui/material/Chip";

// Translation
import { useTranslation } from "react-i18next";

export default function RedemptionHistoryDetail({ ...props }) {
  const { t } = useTranslation("common");

  const { isOpen, isClose, data } = props;

  console.log(data);

  const width = "xs";

  const filterStatus = (data) => {
    switch (data) {
      case "REJECTED":
        return <Chip label="Rejected" color="error" />;
      case "CANCELLED":
        return <Chip label="Cancelled" color="error" />;
      case "REDEEMED":
        return <Chip label="Redeemed" color="success" />;
      default:
        return <Chip label="On Confirmation" color="warning" />;
    }
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={isClose} maxWidth={width}>
      <DialogTitle>
        <Typography variant="h4" fontWeight="600">
          {t("redemption.dialog.title")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sx={{ textAlign: "center", mt: 2 }}>
            <QRCode
              value={"https://bo.gidr.co.id/redeem?id=" + data.id}
              size={145}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body2" color="#7e7e7e" gutterBottom>
              {t("redemption.dialog.amount")}
            </Typography>
            <Typography variant="body2" fontWeight="600">
              {data.amount} GIDR
            </Typography>
          </Grid>
          <Grid item xs={6} md={12}>
            <Typography variant="body2" color="#7e7e7e" gutterBottom>
              {t("redemption.dialog.date")}
            </Typography>
            <Typography variant="body2" fontWeight="600">
              {new Date(data.appointmentDate).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body2" color="#7e7e7e" gutterBottom>
              {t("transaction.modal.status")}
            </Typography>
            {filterStatus(data.status)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={isClose}>{t("transaction.modal.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
