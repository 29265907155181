import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";

// Assets
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { WALLET_API } from "../../lib/axios";

export default function RedemptionSubmitCard({ ...props }) {
  const { t } = useTranslation("common");

  const { amount, date, createdAt, slot, reqID } = props;
  const navigate = useNavigate();

  const auth = localStorage.getItem("auth");

  const onClickHandle = (e) => {
    e.preventDefault();
    WALLET_API.post("account/redeem/cancel", {}, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    })
    .then(() => {
      navigate("/?msg=cancelredeem")
      navigate(0)
    })
    .catch(() => {
      navigate("/?err=cancel")
      navigate(0)
    })
  }
  return (
    <Paper
      sx={{
        p: 2,
        boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
      }}
    >
      <Grid container spacing={1} sx={{ pb: 3 }}>
        <Grid item xs={12} md={12}>
          <Typography variant="h4" sx={{ fontWeight: "600" }} align="left">
            Redemption
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
          <QRCode value={"https://bo.gidr.co.id/redeem?id=" + reqID} size={145}/>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="body2" color="#7e7e7e" align="left">
            {t("redemptionSubmit.amountToRedeem")}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 0.5, fontWeight: "600" }}
            align="left"
          >
            {amount} GIDR
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="#7e7e7e" align="left">
            Tanggal Permohonan
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 0.5, fontWeight: "600" }}
            align="left"
          >
            {dayjs(createdAt).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="#7e7e7e" align="left">
            Collection Date
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 0.5, fontWeight: "600" }}
            align="left"
          >
            {dayjs(date).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="#7e7e7e" align="left">
            Time Slot
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 0.5, fontWeight: "600" }}
            align="left"
          >
            {slot >= 10 ? slot.toString() : "0" + slot}:00 - {slot + 2}:00 WIB
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="right" spacing={2}>
        <Grid item>
          <Button variant="text" onClick={onClickHandle}>Cancel</Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
