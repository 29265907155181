import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Assets
import headerBg from "../assets/header.png";

// Components
import KYCAlert from "../components/alert/KYC";
import RedemptionInfoAlert from "../components/alert/RedeemptionInfo";
import AppBar from "../components/AppBar";
import BalanceCard from "../components/card/Balance";
import PersonalInformationCard from "../components/card/PersonalInformation";
import RedemptionCard from "../components/card/Redemption";
import RedemptionSubmitCard from "../components/card/RedemptionSubmit";
import TopupCard from "../components/card/Topup";
import Snackbar from "../components/Snackbar";
import Title from "../components/Title";

// API
import { WALLET_API } from "../lib/axios";

// Translation
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function Dashboard() {
  const { t } = useTranslation("common");
  // TODO: Translate this
  const messages = (msg) => {
    return msg === "redeem" ? "Redeemed Successfully" : "Redemption Cancelled";
  };

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [openAlert, setOpenAlert] = useState(
    searchParams.get("msg") || searchParams.get("err")
  );
  const [dataStatus, setDataStatus] = useState(!searchParams.get("err"));
  const [message, setMessage] = useState(
    searchParams.get("err")
      ? "Something went wrong"
      : messages(searchParams.get("msg"))
  );
  const [header, setHeader] = useState("Loading...");
  const [profile, setProfile] = useState("Loading...");
  const [wallet, setWallet] = useState("Loading...");
  const [email, setEmail] = useState("Loading...");
  const [verified, setVerified] = useState(false);
  const [balance, setBalance] = useState(0);
  const [price, setPrice] = useState(0);
  const [formData, setFormData] = useState({ to: "", amount: 0 });

  const auth = localStorage.getItem("auth");

  useEffect(() => {
    if (auth == null) {
      navigate("/login");
    } else {
      WALLET_API.get("/account/profile/get", {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then((res) => res.data)
        .then((res) => {
          setHeader(
            res.fullname
              ? `Hi 👋🏻, ${res.fullname}!`
              : t("Hi there, Please complete profile!")
          );
          setProfile(res);
          setWallet(res.walletaddress);
          setBalance(res.amount);
          setEmail(res.email);
          setVerified(res.statusemail === "VERIFIED");
        })
        .catch(() => {
          localStorage.removeItem("auth");
          navigate("/login");
        });
      WALLET_API.get("/account/wallet/price", {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
        .then((res) => res.data)
        .then((res) => {
          setPrice(res.harga_jual ?? 0);
        })
        .catch(() => {
          setPrice(NaN);
        });
    }
  }, [auth, navigate, t]);

  const handleSubmit = () => {
    WALLET_API.post("/account/wallet/send", formData, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    })
      .then((res) => res.data.data)
      .then(() => {
        setMessage(t("alert.gidrTransactionProcess"));
        setOpenAlert(true);
        setDataStatus(true);
      })
      .catch(() => {
        setMessage(t("alert.gidrTransactionFailed"));
        setOpenAlert(true);
        setDataStatus(false);
      });
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <Fragment>
      <Snackbar
        isOpen={openAlert}
        isSuccess={dataStatus}
        isClose={handleCloseAlert}
        message={message}
      />
      <Box
        sx={{
          backgroundImage: `url(${headerBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "350px",
          width: "100vw",
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 1,
          borderRadius: "unset",
          boxShadow: "unset",
        }}
      />
      <AppBar />
      <Container maxWidth="lg" sx={{ p: 1 }}>
        <div style={{ position: "relative" }}>
          <Title
            header={header}
            isBreadcrumbs={false}
            subHeader={new Date().toLocaleDateString()}
          />
        </div>
        <div style={{ position: "relative" }}>
          <Grid
            container
            spacing={2.5}
            sx={{ position: "absolute", pt: 4, left: 0, right: 0 }}
          >
            <Grid item xs={12} md={5}>
              <BalanceCard wallet={wallet} balance={balance} price={price} />
            </Grid>
            <Grid item xs={12} md={7}>
              <PersonalInformationCard email={email} verified={verified} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                {verified ? null : (
                  <Grid item xs={12} md={6}>
                    <KYCAlert
                      description={t("transferForm.notVerified")}
                      data={profile}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={verified ? 12 : 6}>
                  <RedemptionInfoAlert
                    description={"Instruction on How to Redeem?"}
                    data={profile}
                  />
                </Grid>
                <Grid item xs={12} md={profile.ongoingRedeem ? 12 : 6}>
                  <TopupCard />
                </Grid>
                {profile.ongoingRedeem ? null : (
                  <Grid item xs={12} md={6}>
                    <RedemptionCard
                      verified={verified}
                      balance={balance}
                      profile={profile}
                      isTimedOut={profile.timedOut}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {profile.ongoingRedeem ? (
              <Grid item xs={12} md={3} sx={{ mt: 2 }}>
                <RedemptionSubmitCard
                  amount={profile.ongoingRedeem.amount}
                  date={profile.ongoingRedeem.appointmentDate}
                  createdAt={profile.ongoingRedeem.createdAt}
                  slot={profile.ongoingRedeem.timeSlot}
                  reqID={profile.ongoingRedeem.id}
                />
              </Grid>
            ) : null}
            <Grid
              item
              xs={12}
              md={profile.ongoingRedeem ? 9 : 12}
              sx={{ mt: 2, pb: "120px" }}
            >
              <Paper
                sx={{
                  p: 2,
                  boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                }}
              >
                <Grid container spacing={2} sx={{ pb: 3 }}>
                  <Grid item xs={12} md={12} sx={{ pb: 2 }}>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "600" }}
                      align="left"
                    >
                      {t("transferForm.title")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="body2"
                      sx={{ mb: "8px", fontWeight: "600" }}
                    >
                      {t("transferForm.walletAddressLabel")}*
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      disabled={!verified}
                      placeholder={t("transferForm.walletAddressPlaceholder")}
                      inputProps={{
                        style: {
                          fontWeight: 400,
                          fontSize: "14px",
                        },
                      }}
                      name="to"
                      id="to"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{ mb: "8px", fontWeight: "600" }}
                    >
                      {t("transferForm.gidrAmountLabel")}*
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      disabled={!verified}
                      placeholder={t("transferForm.gidrAmountPlaceholder")}
                      InputProps={{
                        style: {
                          fontWeight: 400,
                          fontSize: "14px",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "600" }}
                            >
                              GIDR
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                      name="amount"
                      id="amount"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <hr style={{ border: "0.5px solid #eeeeee" }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="body2"
                      color="#aab0b6"
                      sx={{ mb: "8px", fontWeight: "600" }}
                    >
                      {t("transferForm.transactionFeeLabel")}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        style: {
                          fontWeight: 400,
                          fontSize: "14px",
                          backgroundColor: "#eeeeee",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "600" }}
                            >
                              GIDR
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      value={0}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="body2"
                      color="#aab0b6"
                      sx={{ mb: "8px", fontWeight: "600" }}
                    >
                      {t("transferForm.grandTotalLabel")}
                    </Typography>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        style: {
                          fontWeight: 400,
                          fontSize: "14px",
                          backgroundColor: "#eeeeee",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "600" }}
                            >
                              GIDR
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                      value={formData.amount}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="right"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="text"
                      onClick={handleSubmit}
                      disabled={!verified}
                    >
                      {t("transferForm.transfer")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="text" sx={{ color: "#141517" }}>
                      {t("transferForm.reset")}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Fragment>
  );
}
