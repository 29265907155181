import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FormControlLabel, Switch } from "@mui/material";

// Components
import HistoryTable from "../../components/table/Transaction";

// Translation
import { useTranslation } from "react-i18next";

export default function TransactionHistory({ ...props }) {
  const { t } = useTranslation("common");

  const { data } = props;

  const [showOPDebit, setShowOPDebit] = useState(false);

  return (
    <Grid container spacing={2} sx={{ pt: 1.5 }}>
      <Grid item xs={12} md={12}>
        <Typography variant="body1" fontWeight="600" align="left">
          {t("transaction.list")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <HistoryTable data={data} showOnProcess={showOPDebit} type={"debit"} />
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: "right" }}>
        <FormControlLabel
          control={
            <Switch
              checked={showOPDebit}
              onChange={(e) => setShowOPDebit(e.target.checked)}
            />
          }
          label={
            <Typography variant="body2" align="left">
              {t("transaction.showOnProcess")}
            </Typography>
          }
        />
      </Grid>
    </Grid>
  );
}
