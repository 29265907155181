import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Translation
import { useTranslation } from "react-i18next";

function RedemptionInfoStepper() {
  const { t } = useTranslation("common");

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Stepper
            sx={{
              "& .MuiStepConnector-line": {
                minHeight: "15px",
              },
            }}
            orientation="vertical"
          >
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step1")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step2")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step3")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step4")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel sx={{ mb: 1 }}>
                {t("redeemGIDR.modal.step5")}
              </StepLabel>
              <iframe
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8&q=Galeri24%20Office%20Center%20on%20Jl.%20Salemba%20Raya%20No.2%2C%20Kenari%2C%20Jakarta%20Pusat&zoom=10&maptype=roadmap"
                width={"100%"}
                height={350}
                style={{ border: 0, marginBottom: 10 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                title="Humanity First Indonesia"
              />
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step6")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step7")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step8")}</StepLabel>
            </Step>
          </Stepper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function RedemptionInfoDialog({ ...props }) {
  const { t } = useTranslation("common");

  const { isOpen, isClose } = props;

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={isClose}
      maxWidth={"sm"}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Typography variant="h4" fontWeight="600">
          {t("redeemGIDR.modal.description")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <RedemptionInfoStepper />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={isClose}>{t("redeemGIDR.modal.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
