import React, { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

// React Record Webcam
// import VideoRecorder from "react-video-recorder";
import { useRecordWebcam } from "react-record-webcam";

// Date Picker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

// Material Icons
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// Phone Input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Assets
import completedImg from "../../assets/completed.svg";
import warningImg from "../../assets/warning.svg";

const OPTIONS = {
  filename: "test-filename",
  fileType: "mp4",
  width: 1920,
  height: 1080,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const steps = ["Personal Information", "Liveness Check"];

export default function HorizontalLinearStepper({ ...props }) {
  const recordWebcam = useRecordWebcam(OPTIONS);
  const { data, isClose } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const getRecordingFileHooks = async () => {
    const blob = await recordWebcam.getRecording();
    console.log({ blob });
  };
  const getRecordingFileRenderProp = async (blob) => {
    console.log({ blob });
  };

  console.log(getRecordingFileHooks);
  console.log(getRecordingFileRenderProp);

  const showStepperData = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={1} sx={{ mt: 3, mb: 2 }}>
            <Grid item xs={12} md={12}>
              <Button
                component="label"
                fullWidth
                size="large"
                variant="outlined"
                sx={{
                  boxShadow: "unset",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload KTP
                <VisuallyHiddenInput type="file" accept="image/*" />
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                NIK
              </Typography>
              <TextField
                required
                fullWidth
                placeholder="NIK"
                inputProps={{
                  style: {
                    fontWeight: 400,
                    fontSize: "14px",
                    height: "15px",
                  },
                }}
                name="nik"
                value={data?.nik}
                id="nik"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                Fullname
              </Typography>
              <TextField
                required
                fullWidth
                placeholder="Fullname"
                value={data?.fullname}
                inputProps={{
                  style: {
                    fontWeight: 400,
                    fontSize: "14px",
                    height: "15px",
                  },
                }}
                name="fullname"
                id="fullname"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                Gender
              </Typography>
              <FormControl>
                <RadioGroup row>
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label={<Typography variant="body2">Male</Typography>}
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label={<Typography variant="body2">Female</Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                Place of Birth
              </Typography>
              <TextField
                required
                fullWidth
                placeholder="Place of Birth"
                inputProps={{
                  style: {
                    fontWeight: 400,
                    fontSize: "14px",
                    height: "15px",
                  },
                }}
                name="pob"
                id="pob"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                Date of Birth
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"en-gb"}
              >
                <DatePicker
                  name="dob"
                  id="dob"
                  value={dayjs(data?.dob)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "50px",
                      fontSize: "14px",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                Email Address
              </Typography>
              <TextField
                required
                fullWidth
                type="email"
                placeholder="Email Address"
                value={data?.email}
                inputProps={{
                  style: {
                    fontWeight: 400,
                    fontSize: "14px",
                    height: "15px",
                  },
                }}
                name="email"
                id="email"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                Phone Number
              </Typography>
              <PhoneInput
                country={"id"}
                value={data?.phonenumber}
                inputStyle={{
                  height: "48px",
                  width: "100%",
                  paddingLeft: "45px",
                }}
                inputProps={{
                  name: "phonenumber",
                  id: "phonenumber",
                }}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={1} sx={{ mt: 3, mb: 2 }}>
            <Grid item xs={12} md={12}>
              {/* <VideoRecorder
                isOnInitially
                showReplayControls
                isFlipped={false}
                // mimeType={text('mimeType')}
                countdownTime={3000}
                timeLimit={60000}
                onRecordingComplete={(videoBlob) => {
                  // Do something with the video...
                  console.log("videoBlob", videoBlob);
                }}
              /> */}
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid container spacing={1} sx={{ mt: 3, mb: 2 }}>
            <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
              <img src={warningImg} width="185x" alt="GIDR - Warning" />
              <Typography
                variant="body2"
                sx={{ mb: 2, mt: 2 }}
                fontWeight="bold"
                align="center"
              >
                It looks like we have some problem, please try again!
              </Typography>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <Fragment>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
              <img src={completedImg} width="185x" alt="GIDR - Completed" />
              <Typography
                variant="body2"
                sx={{ mb: 2, mt: 2 }}
                fontWeight="bold"
                align="center"
              >
                KYC Verification Process Completed 🎉
                <br />
                Your Digital Certificate will be sent to Your Email
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={isClose}>Close</Button>
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          {showStepperData(activeStep)}
          <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Verify" : "Next"}
            </Button>
          </Box>
        </Fragment>
      )}
    </Box>
  );
}
